<template>
  <div>
    <div class="content-tile">
      <div class="su-title">
        <i class="el-icon-document-copy"></i>
        <span>销售员管理</span>
      </div>
      <div>
        <el-button
            type="primary"
            @click="addRole"
            v-if="$store.state.routerRole.indexOf('24')!=-1 || $store.state.routerRole.indexOf('1')!=-1"
        >
          添加销售员
        </el-button>
      </div>
    </div>

    <div class="tab pt-12">
      <el-tabs v-model="tabActiveName" @tab-click="tabsClick">
        <el-tab-pane name="">
          <span slot="label">全部</span>
        </el-tab-pane>
        <el-tab-pane name="1">
          <span slot="label">已启动</span>
        </el-tab-pane>
        <el-tab-pane name="2">
          <span slot="label">已关闭</span>
        </el-tab-pane>
      </el-tabs>
      <div class="tab-search">
        <el-input
            placeholder="请输入角色名"
            v-model="keyWord"
        >
          <i slot="suffix" class="el-icon-search" @click="searchClick"></i>
        </el-input>
      </div>
    </div>

    <el-table
        ref="table"
        :data="tableData"
        border
        style="width: 100%"
        :row-class-name="tableRowClassName"
        :header-cell-style="{
                      'color': '#18a78a'}"
        v-loading="$store.state.loading"
    >
      <el-table-column
          label="账号"
          prop="accountNumber"
          min-width="120px"
          align="center"
          :show-overflow-tooltip="true"
      >
      </el-table-column>

      <el-table-column
          label="头像"
          prop="userName"
          min-width="120px"
          align="center"
          :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <img v-if="scope.row.headPortrait" :src="scope.row.headPortrait" style="width: 60px; height: 60px">
        </template>
      </el-table-column>

      <el-table-column
          label="姓名"
          prop="userName"
          min-width="120px"
          align="center"
          :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
          label="联系电话"
          prop="contactNumber"
          min-width="120px"
          align="center"
          :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
          label="询单数"
          prop="inquiryNumber"
          min-width="120px"
          align="center"
          :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
          label="订单数"
          prop="orderNumber"
          min-width="120px"
          align="center"
          :show-overflow-tooltip="true"
      >
      </el-table-column>

      <el-table-column
          label="创建时间"
          prop="creationTime"
          min-width="120px"
          align="center"
          :show-overflow-tooltip="true"
      >
      </el-table-column>

      <el-table-column
          label="状态"
          prop="status"
          min-width="120px"
          align="center"
          :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          {{ scope.row.status | lockStatus }}
        </template>
      </el-table-column>
      <el-table-column
          label="操作"
          width="200px"
          align="center"
      >
        <template slot-scope="scope">
          <el-button type="primary" @click="editClick(scope.row)">编辑</el-button>
          <el-button type="warning" v-if="scope.row.status!=='1'" @click="statusClick(scope.row,1)">开启</el-button>
          <el-button type="info" v-if="scope.row.status!=='2'" @click="statusClick(scope.row,2)">关闭</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
        :append-to-body="true"
        ref="table"
        title="添加销售员"
        :visible.sync="roleDialog"
        width="650px"
        :before-close="roleDialogClose"
        :close-on-press-escape="false"
    >
      <div>
        <div class="content-main-title">
          <span>基本信息</span>
          <div class="hr"></div>
        </div>
        <div class="dialog-name">
          <ul>
            <li>
              <span>头像</span>
              <div class="screenshot-upload">
                <el-upload
                    :action="screenshotAction"
                    :show-file-list="false"
                    list-type="picture-card"
                    :auto-upload="true"
                    :before-upload="screenshotUpload"
                    :on-error="screenshotError"
                    :on-success="screenshotSuccess"
                    :multiple="false"
                    name="file"
                    :data="screenshotData"
                >
                  <img v-if="dialogData.headPortrait" :src="dialogData.headPortrait" style="width: 110px;height: 110px">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>

              </div>
            </li>
            <li>
              <span>姓名</span>
              <el-input v-model="dialogData.userName" placeholder="请输入"></el-input>
            </li>
            <li>
              <span>联系电话</span>
              <el-input v-model="dialogData.contactNumber" placeholder="请输入"></el-input>
            </li>
          </ul>
        </div>
        <div class="content-main-title">
          <span>账号信息</span>
          <div class="hr"></div>
        </div>

        <div class="dialog-name">
          <ul>
            <li>
              <span>账号状态</span>
              <el-switch
                  v-model="dialogData.accountStatus"
              >
              </el-switch>
            </li>
            <li>
              <span>账号</span>
              <el-input v-model="dialogData.accountNumber" placeholder="请输入"></el-input>
            </li>
            <li>
              <span>密码</span>
              <el-input v-model="dialogData.password" placeholder="请输入"></el-input>
            </li>
            <li>
              <span>销售主管</span>
              <el-radio v-model="dialogData.manager" label="1">是</el-radio>
              <el-radio v-model="dialogData.manager" label="0">否</el-radio>
            </li>
          </ul>
        </div>

      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="roleDialogClose">取 消</el-button>
        <el-button type="primary" @click="roleDialogSub">确 定</el-button>
      </div>
    </el-dialog>
    <div class="footerPage">
      <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="30"
          layout="total, prev, pager, next"
          :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {currentListData, currentListEdit, currentListPut, currentListStatus} from '@/api/module/basic'
export default {
  components: {},
  props: [],
  data() {
    return {
      // 头像
      screenshotAction: process.env.VUE_APP_URL + '/api/upload/uploadFile',
      screenshotData: {remarks: 1}, //上传添加的字段
      tabActiveName: '', // tab当前选择哪个
      roleDialog: false,
      page: 1,
      //dialog数据
      dialogData: {
        id:'',
        headPortrait: '',//头像
        userName: '',//用户名
        contactNumber: '',//联系电话
        accountStatus: true,//账号状态
        accountNumber: '',//账号
        password: '',//密码
        manager:'0' //是否是销售主管 0 否 1是
      },
      total: 0,//全部
      keyWord: '',//搜索
      // 表格数据
      tableData: [
     /*   {
          accountNumber: '11',//账号
          headPortrait: 'https://gc.hc-info.cn/storage/JqV4Ss1P0ggz12iazSIRvNLb3FsPb6mv1w8q7XL1.jpeg',//头像
          userName: '22',//姓名
          contactNumber: '',//联系电话
          inquiryNumber: '33',//询单数
          orderNumber: '232',//订单数
          creationTime: '22',//创建时间
          status: '44',//状态
        }*/
      ]
    };
  },
  //过滤器
  filters: {
    lockStatus(val) {
      switch (val) {
        case '1':
          return '启用'
        case '2':
          return '关闭'
      }
    }
  },
  mounted() {
    this.initLoad();
  },
  methods: {
    //初始化数据
    initLoad() {
      this.$store.state.loading = true;
      let params = {
        user_type: 2,
        page: this.page,
        lock_status: this.tabActiveName === '0' ? '' : this.tabActiveName,
        role: this.keyWord
      }
      currentListData(params).then((result) => {
        this.total = result.data.total;
        this.page = result.data.current_page;
        this.tableData = result.data.data.map(item => ({
          id: item.id,
          accountNumber: item.account,//账号
          userName: item.username,//用户名
          password:item.password,//密码
          role: item.role_id,//角色
          roleName:item.role_name, //角色名称
          creationTime: this.Utils.timeDate(item.created_at),//创建时间
          status: item.lock_status.toString(),//状态
          headPortrait: item.avatar, //头像

          contactNumber: item.phone,//联系电话
          inquiryNumber: item.seek_order_num,//询单数
          orderNumber: item.order_num,//订单数
          manager:item.manager.toString()
        }));
      })
    },
    //搜索
    searchClick(){
      this.page=1;
      this.initLoad();
    },
    //  tab 被点击
    tabsClick() {
      this.page = 1;
      this.initLoad();
    },
    //点击添加账号
    addRole() {
      this.roleDialog = true;
    },
    //编辑按钮
    editClick(row) {
      row.accountStatus=row.status==1?true:false//状态
      this.dialogData = {...row};
      console.log( this.dialogData)
      this.chooseARole=row.role_id;
      this.roleDialog = true;
    },
    //状态更改
    statusClick(row, status) {
      console.log(row.id, status)
      let data = {
        id: row.id,
        lock_status: status
      }
      currentListStatus(data).then(() => {
        this.$message.success('修改状态成功');
        this.initLoad();
      })
    },
    //分页
    handleCurrentChange(val) {
      this.page = val;
      this.initLoad();
    },
    //dialog 点击确定
    roleDialogSub() {
      let data = {
        user_type: 2,
        id: this.dialogData.id,
        avatar: this.dialogData.headPortrait,//头像
        username: this.dialogData.userName,//用户名
        phone: this.dialogData.contactNumber,//联系电话
        role_id: this.dialogData.chooseARole,//选择角色
        lock_status: this.dialogData.accountStatus ? 1 : 2,//账号状态
        account: this.dialogData.accountNumber,//账号
        password: this.dialogData.password,//密码
        manager:this.dialogData.manager
      }
      if (this.dialogData.id == '') {
        currentListPut(data).then(() => {
          this.initLoad();
          this.roleDialogClose();
        })
      } else {
        console.log(this.dialogData.id)
        currentListEdit(data).then(() => {
          this.initLoad();
          this.roleDialogClose();
        })
      }
      this.roleDialogClose();
    },
    //dialog 关闭时候调用
    roleDialogClose() {
      this.dialogData = this.$options.data().dialogData;
      this.roleDialog = false;
    },
    //表格 第几行显示什么颜色
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 0) {
        return 'warning-row';
      }
      return '';
    },
    //头像 上传失败的钩子
    screenshotError() {
      this.$message.error('截图上传失败上传失败');
    },
    //头像 上传成功钩子
    screenshotSuccess(file) {
      this.dialogData.headPortrait = file.data;
    },
    //头像 上传文件之前的钩子
    screenshotUpload(file) {
      const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png');
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
  },
};
</script>
<style lang="scss">
//截图上传
.screenshot-upload {
.el-upload--picture-card, .el-upload-list__item {
  width: 120px;
  height: 120px;
  line-height: 120px;
}

.el-icon-check {
  position: absolute;
  left: 14px;
  top: 0px;
}
}
</style>
<style lang="scss" scoped>
.content-main-title {
  margin: 12px 0;
}

.dialog-name li {
  @include flex();
  margin-bottom: 12px;

  span {
    width: 70px;
    flex: 0 0 auto;
    margin-left: 12px;
  }
}



</style>
